import React from 'react';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { ContainerImg } from './Styled';

interface ImgProps {
  src: any;
  width: string;
  height: string;
}

const Image: React.FC<ImgProps> = ({ src, width, height }) => (
  <ContainerImg width={width} height={height}>
    <LazyPicture sources={src} backgroundColor="#f4f4f4" alt="produto" />
  </ContainerImg>
);

Image.defaultProps = {
  src: '',
};

export default Image;
export { Image };

import React from 'react';
import { useCookies } from './cookies';

export interface WithCustomerProps {
  customer: Customer;
}

interface Customer {
  id: string;
  token: string;
  nickname: string;
  hasPrime: boolean;
  avatar: string;
  finance: boolean;
}

export const withCustomer = <P extends WithCustomerProps>(Component: React.FC<P>) => {
  const InnerComponent: React.FC<P> = props => {
    const [cookies] = useCookies();
    const customer = {
      id: cookies['customer.id'],
      token: cookies['customer.api.token'],
      nickname: cookies.nickname,
      hasPrime: cookies['c_prime'] === 'true',
      avatar: cookies.b2wFacebookAvatar,
      finance: cookies['c_finance'] === 'hasCard',
    };
    return <Component {...props} customer={customer} />;
  };
  return InnerComponent;
};

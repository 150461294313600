import styled from 'styled-components';

interface CardStyledProps {
  borderRadius: number;
  widthMargin: number;
  background: boolean;
  noCarousel?: boolean;
}

const CardStyled = styled.div<CardStyledProps>`
  display: flex;
  position: relative;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  overflow: hidden;
  margin: 0 auto;
  transition: all 0.2s ease-out;
  width: ${({ noCarousel }) => (noCarousel ? '100%' : '515px')};
  max-width: ${({ widthMargin }) => `calc(100% - ${widthMargin}px)`};
  background: ${({ background }) => (background ? '#fff' : 'transparent')};

  &.shadow {
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.1);
    max-width: 100%;
  }
`;

export default CardStyled;
export { CardStyled };

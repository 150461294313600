const formatDate = (apiDate: string) => {
  const date = new Date(apiDate);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;

  const months = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ];

  const monthName = months[month - 1];

  return `Entregue em ${day} de ${monthName}`;
};

export default formatDate;
export { formatDate };

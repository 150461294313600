import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  padding: 10px 0 10px 4px;
`;

const ContainerDetails = styled.div`
  width: 100%;
  padding: 24px;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #282828;
  padding-top: 6px;
  padding-bottom: 16px;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  align-items: center;
`;
const DeliveryDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #868686;
`;
const Anchor = styled.div`
  cursor: pointer;
  color: #f80032;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export { Content, ContainerDetails, Title, Wrapper, DeliveryDate, Anchor };

import styled from 'styled-components';

interface ImageProps {
  width: string;
  height: string;
}

export const ContainerImg = styled.div<ImageProps>`
  display: flex;
  width: ${({ width }) => (width ? width : '90px')};
  padding: 16px;
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
`;

/* istanbul ignore file */
import React from 'react';
import styled from 'styled-components';
import { withMissingReviews } from '@catalogo/service-americanas-missing-reviews/src/hocs';
import { CarouselReviews, FewReviews } from './components/template';

const MissingReviews = props => {
  const { reviews, dispatchMetrics, experiment } = props;

  if (experiment != 'new') {
    return null;
  }

  if (reviews.length == 0) {
    return null;
  }

  if (reviews.length <= 2) {
    return (
      <>
        <TitleComponent>sua opinião ajuda muuuito</TitleComponent>
        <FewReviews reviews={reviews} dispatchMetrics={dispatchMetrics} />
      </>
    );
  }

  return (
    <>
      <TitleComponent>sua opinião ajuda muuuito</TitleComponent>
      <CarouselReviews reviews={reviews} dispatchMetrics={dispatchMetrics} />
    </>
  );
};

const TitleComponent = styled.span`
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #666;
  padding-bottom: 16px;
  padding-left: 10px;
`;

export { MissingReviews };
export default withMissingReviews(MissingReviews);

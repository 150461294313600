import React from 'react';
import styled from 'styled-components';

interface NameProps {
  nameProduct?: string;
}

const ProductName: React.FC<NameProps> = ({ nameProduct }) => <NameProduct>{nameProduct}</NameProduct>;

export { ProductName };

const NameProduct = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #575757;
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

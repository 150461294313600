import React from 'react';
import { Carousel } from '@catalogo/theme-carousel-with-bullets';
import { CardReview } from '../../organisms/CardReview';

const CarouselReviews = (props: any) => {
  const { reviews, dispatchMetrics } = props;
  const renderReviews = reviews?.map((review: any) => {
    const { image, productId, name, deliveryId, orderId, deliveryDate, sku } = review;

    return (
      <CardReview
        key={productId}
        srcImg={image}
        apiDate={deliveryDate}
        productName={name}
        productId={productId}
        deliveryId={deliveryId}
        orderId={orderId}
        skuId={sku}
        dispatchMetrics={dispatchMetrics}
      />
    );
  });

  return <Carousel gap={13}>{renderReviews}</Carousel>;
};

export default CarouselReviews;
export { CarouselReviews };

/* istanbul ignore file */
import { useQuery } from '@apollo/client';

import { queryMissingReviews } from '../../queries';

const defaultParam = {
  monthsOld: 6,
  limit: 12,
};

const useQueryMissingReviews = (customerId: string) => {
  const query = useQuery(queryMissingReviews, {
    variables: { ...defaultParam, customerId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !customerId,
  });

  return { ...query, defaultParam };
};

export default useQueryMissingReviews;
export { useQueryMissingReviews, defaultParam };

import React from 'react';
import { Anchor, ArrowUI } from './Styled';

interface AnchorComponentProps {
  href: string;
  dispatchMetrics: (arg1: string, arg2: any) => void;
}

const AnchorComponent = (props: AnchorComponentProps) => {
  const { href, dispatchMetrics } = props;

  return (
    <Anchor
      href={href}
      onClick={() =>
        dispatchMetrics('event:track', {
          category: 'missing-review',
          action: 'missingReviewClick',
          label: 'review-placement-key',
        })
      }
    >
      {`avaliar produto `}
      <ArrowUI />
    </Anchor>
  );
};

export { AnchorComponent };

import styled from 'styled-components';
import ArrowIcon from '@catalogo/theme-svg/arrow.svg';

const Anchor = styled.a`
  display: flex;
  cursor: pointer;
  color: #f80032;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
const ArrowUI = styled(ArrowIcon)`
  margin: 3px 10px;
  fill: #f80032;
  width: 9px;
  height: 9px;
  transform: rotate(-90deg);
`;
export { Anchor, ArrowUI };

import React from 'react';
import { CardStyled } from './Styled';

interface CardProps {
  children: JSX.Element;
  borderRadius?: number;
  shadow?: boolean;
  background?: boolean;
  widthMargin?: number;
  height?: number;
  width?: number;
  srcImg?: string;
  noCarousel?: boolean;
  dispatchMetrics: (metricName: string, data: any) => void;
}

const Card = (props: CardProps) => {
  const { widthMargin = 60, background = true, children, shadow = true, borderRadius = 12, noCarousel } = props;
  const className = shadow ? 'shadow' : '';

  return (
    <CardStyled
      noCarousel={noCarousel}
      widthMargin={widthMargin}
      background={background}
      className={className}
      borderRadius={borderRadius}
    >
      {children}
    </CardStyled>
  );
};

Card.defaultProps = {};

export default Card;
export { Card };

import React from 'react';
import { Stars } from '@catalogo/theme-stars';
import { formatDate } from '../../../helpers';
import { Card } from '../../molecules';
import { Image, ProductName, AnchorComponent } from '../../atoms';
import { ContainerDetails, Title, Wrapper, DeliveryDate, Content } from './Styled';

interface CardReviewProps {
  srcImg: string;
  apiDate: string;
  productName: string;
  productId?: number;
  skuId?: number;
  orderId?: number;
  deliveryId?: number;
  noCarousel?: boolean;
  dispatchMetrics: () => void;
}

const CardReview = (props: CardReviewProps) => {
  const { srcImg, apiDate, productName, productId, skuId, deliveryId, noCarousel, orderId, dispatchMetrics } = props;
  const src = {
    desktop: {
      src: srcImg,
      ratio: '1:1',
    },
  };
  const sentenceDate = formatDate(apiDate);
  const href = `/avaliacao/${productId}?skuId=${skuId}&deliveryId=${deliveryId}&orderId=${orderId}&chave=tc_hm_dt_1__acom_atalho_review`;

  return (
    <Content>
      <Card shadow={true} noCarousel={noCarousel} dispatchMetrics={() => null}>
        <>
          <Image src={src} width="159px" height="158px" />
          <ContainerDetails>
            <Stars rating={0} size={19} gapBetweenStars={-1} />
            <Title>avalie sua compra :)</Title>
            <ProductName nameProduct={productName} />
            <Wrapper>
              <DeliveryDate>{sentenceDate}</DeliveryDate>
              <AnchorComponent href={href} dispatchMetrics={dispatchMetrics} />
            </Wrapper>
          </ContainerDetails>
        </>
      </Card>
    </Content>
  );
};

export { CardReview };
export default CardReview;

import React from 'react';
import { Wrapper } from './Styled';
import { CardReview } from '../../organisms/CardReview';

const FewReviews = (props: any) => {
  const { reviews, dispatchMetrics } = props;
  const renderReviews = reviews?.map((review: any) => {
    const { image, productId, name, deliveryId, orderId, deliveryDate, sku } = review;

    return (
      <CardReview
        key={productId}
        srcImg={image}
        apiDate={deliveryDate}
        productName={name}
        productId={productId}
        deliveryId={deliveryId}
        orderId={orderId}
        skuId={sku}
        noCarousel={true}
        dispatchMetrics={dispatchMetrics}
      />
    );
  });

  return <Wrapper>{renderReviews}</Wrapper>;
};

export default FewReviews;
export { FewReviews };

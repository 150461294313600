/* istanbul ignore file */
import React from 'react';
import compose from 'lodash/fp/compose';
import { withMetrics } from '@catalogo/core-metrics';
import { withCustomer } from '@catalogo/core-connect/customer';
import { useTestAB } from '@catalogo/core-experiments';
import { useQueryMissingReviews } from '../../hooks';

const serviceMissingReviews: any = (Component: any) => (props: any) => {
  const { customer } = props;
  const customerId = customer?.id;
  const result = useQueryMissingReviews(customerId);
  const reviews = result?.data?.missingReviews?.missingReviews ? result?.data?.missingReviews?.missingReviews : [];
  let experiment;

  if (customerId) {
    experiment = useTestAB('EXP_UGC_MISSING_REVIEWS');
  }

  if (!customerId || result?.error) {
    return null;
  }

  return <Component {...props} reviews={reviews} experiment={experiment} />;
};

const withMissingReviews = compose(withCustomer, withMetrics, serviceMissingReviews);

export default withMissingReviews;
export { withMissingReviews };
